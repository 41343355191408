import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <LogoIcon />
        </div>
        exilium
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" href="#roadmap">
          Roadmap
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <a className="
        text-sm  
        py-3 px-8 
        bg-blue-600
        hover:bg-blue-700
        hover:text-white
        rounded
        text-white" 
        href="https://exchange.exilium.com">Exchange</a>
      </div>
    </div>
  </header>
);

export default Header;
